<template>
  <div class="bg" @click="clickSignup">
    <div class="logo">
      <img src="./assets/casino365.png" class="w-100" />
    </div>
    <div class="marquee">
      <img v-for="i in 5" :key="i" src="./assets/marquee.png" />
    </div>
    <div class="info">
      <img src="./assets/ds88.jpg" />
      <img v-for="i in 3" :key="i" :src="require(`./assets/info${i}.png`)" />
    </div>
    <div class="footer">
      <img src="./assets/signup.png" />
      <img src="./assets/tg.png" v-on:click.stop @click="clickTg" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clickSignup() {
      const code =
        new URLSearchParams(window.location.search).get('a') || 'VXU8326';
      window.location = `https://www.casino365online.com/signup?a=${code}`;
    },
    clickTg() {
      window.open('https://t.me/C36524', '_blank');
    },
  },
};
</script>

<style>
body {
  margin: 0;
}
.bg {
  background: #070707;
  min-height: 100vh;
  overflow-x: hidden;
}
.logo {
  background: #35383a;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  width: 100%;
  max-width: 200px;
}
.marquee {
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
  animation: marquee 30s linear infinite;
  margin-top: 10px;
}
.marquee img {
  height: 45px;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-60%);
  }
}
.info {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 70px;
}
.info img {
  max-width: 500px;
  width: 100%;
}
.footer {
  background: linear-gradient(to bottom, #292c35, #0f0f07 100%);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.footer img {
  height: 50%;
  margin: 0 20px;
  cursor: pointer;
}
</style>
